@import '../../../../Frontend/Scss/layout-variables';
@import '../../../../Frontend/Scss/mixins/button-mixins';

.button-link-block {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 1.563rem;

  @media(min-width: $md) {
    margin-bottom: 3.125rem;
  }

  .button-wrapper {
    display: flex;
    justify-content: flex-start;
  }

  .button {
    @include button-style-lg;
  }
}